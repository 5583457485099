import { Component, OnInit } from '@angular/core';
import {MetaAndTitle} from '../shared/metaAndTitle/metaAndTitle';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private metaAndTitle: MetaAndTitle) { }

  ngOnInit(): void {
    this.metaAndTitle.setMetaAndTitle('Institucional', '/institucional');
  }

}
