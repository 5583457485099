<div class="container paddingContainer">
    <h1>Programação  <mat-divider></mat-divider></h1>

    <mat-accordion>
        <mat-expansion-panel *ngFor="let day of schedule" [expanded]="getDay() === day.day.toLowerCase()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                  <h2 class="margin-none"> {{day.day}}</h2>
                </mat-panel-title>
<!--                            <mat-panel-description>-->
<!--                                Currently I am {{panelOpenState ? 'open' : 'closed'}}-->
<!--                            </mat-panel-description>-->
            </mat-expansion-panel-header>
            <p *ngFor="let program of day.list">
                {{clockFormat(program.hora)}} - <span class="font-weight-bold">{{program.programa}}</span> com {{program.nome}}
                <button *ngIf="currentSchedule === program.programa" mat-raised-button color="warn">NO AR</button>
            </p>
        </mat-expansion-panel>
    </mat-accordion>

</div>
