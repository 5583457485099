import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class MailProvider {

    private apiBase = 'https://radioagudo.com.br/api/mail';

    constructor(public http: HttpClient) {
    }

    sendMail(contactForm) {
        return new Promise((resolve, reject) => {
            this.http.post(this.apiBase, contactForm).subscribe((result: any) => {
                    resolve(result);
                },
                (error) => {
                    reject(error);
                });
        });
    }

}
