<section style="margin-top: 2%">
    <div #search class="container" style="padding-bottom: 20%">
        <h1>Busca de notícias</h1>
        <p>Encontrados {{maxResults}} resultados em {{maxPages}} páginas para <span class="italicKeyword" *ngFor="let key of keywords">{{key}} </span></p>
        <div class="row">
            <ng-container *ngFor="let noticia of newsSearch">

                <div class="col-md-6">
                    <a [routerLink]="[noticia.urlPath]">
                        <div class="shadow card mb-3  esmaecido" style=" border: 1px solid #c1c7d0">
                            <div class="row" style="padding:2%;">
                                <div *ngIf="!noticia.foto.includes('semfoto') && !noticia.foto.includes('http')"
                                     class="col-sm">
                                    <div style="text-align: center; width: 100%;">
                                        <img loading="lazy" 
                                             src="https://radioagudo.com.br{{noticia.foto}}"
                                             class="card-img align-middle"
                                             [alt]="noticia.titulo">
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <h3 class="text-gray-dark">
                                        {{noticia.titulo}}</h3>
                                    <p class="description">{{noticia.subtitulo}}
                                    </p>
                                    <p class="text-black-50  text-sm">{{noticia.datetime | timeAgo}}
                                        em {{noticia.categoria.toLowerCase() | titlecase}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

            </ng-container>
            <section class="container-end row-reverse" *ngIf="maxResults != 0">
                <div class="item">
                    <button [disabled]="page === 1" (click)="prevPage(); scroll(search)" mat-button  color="primary">
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                        Página anterior
                    </button>
                </div>
                <div class="item">
                    <button mat-raised-button> Página {{page}} de {{maxPages}}</button>
                </div>
                <div class="item">
                    <button [disabled]="page === maxPages || maxPages === 0" (click)="nextPage(); scroll(search)"
                            mat-button
                            color="primary">
                        Próxima página
                        <fa-icon [icon]="['fas', 'arrow-right']" ></fa-icon>
                    </button>
                </div>
            </section>
        </div>
    </div>
</section>


