import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ObituaryProvider} from '../../providers/obituary/obituaryProvider';
import {Obituary} from '../../models/Obituary';
import {RemoveCaracteres} from '../../pipes/removeCaracteres';
import {MetaAndTitle} from '../shared/metaAndTitle/metaAndTitle';

@Component({
  selector: 'app-obituary',
  templateUrl: './obituary.component.html',
  styleUrls: ['./obituary.component.css']
})
export class ObituaryComponent implements OnInit {

  public obituary: Obituary;
  public obituaries: Obituary[];

  constructor(private activatedRoute: ActivatedRoute,
              private metaAndTitle: MetaAndTitle,
              private router: Router,
              private obituaryProvider: ObituaryProvider) { }

  ngOnInit(): void {
    this.getObituaryId();
  }

  getObituaryId() {
    this.activatedRoute.params.subscribe(params => {
      // this.params = params;
      this.getObituaryById(params['id']);
    });
  }

  getLastObituary() {
    this.obituaryProvider.getLastDeaths().then((obituary: Obituary[]) => {
      this.obituaries = obituary;
    });
  }

  getObituaryById(id) {
    this.obituaryProvider.getObituaryById(id).then((result: Obituary) => {
      this.metaAndTitle.setMetaAndTitle(result.titulo,
          this.router.url, '', '', 'Falecimentos');
      this.obituary = result;
      this.getLastObituary();
    }).catch(reason => console.error(reason));
  }


  removeAcento(value) {
    const pipeCaracteres = new RemoveCaracteres();
    return pipeCaracteres.transform(value);
  }

}
