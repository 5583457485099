<nav
  id="navbar-main shadow"
  class="navbar navbar-main navbar-expand-lg navbar-transparent backgroundHeader navbar-light headroom headroom--top headroom--pinned"
>
  <div class="container">
    <div class="row d-md-none" style="width: 100%; margin: 0">
      <div class="col col-lg-2" style="text-align: left; padding-left: 0">
        <button
          style="padding-left: 0"
          class="navbar-toggler"
          aria-label="Menu"
          type="button"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="navbar_global"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="col col-lg-2" style="text-align: right; padding-right: 0">
        <img
          alt="Rádio Agudo"
          [routerLink]="['/home']"
          src="./assets/img/brand/brandColored.webp"
          style="max-height: 35px"
        />
      </div>
    </div>
    <div
      class="navbar-collapse collapse"
      id="navbar_global"
      [ngbCollapse]="isCollapsed"
    >
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/home']">
              <img
                loading="lazy"
                src="https://radioagudo.com.br/novosite/logo2.png"
              />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed"
              aria-controls="navbar_global"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li class="nav-item dropdown">
          <a
            [routerLink]="'noticias'"
            class="nav-link no-caret"
            data-toggle="dropdown"
            role="button"
          >
            <span class="nav-link-inner--text">
              <fa-icon
                [icon]="['fas', 'newspaper']"
                style="font-size: 1rem"
              ></fa-icon>
              Notícias</span
            >
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            [routerLink]="'audios'"
            class="nav-link no-caret"
            data-toggle="dropdown"
            role="button"
          >
            <span class="nav-link-inner--text"
              ><fa-icon
                [icon]="['fas', 'headphones']"
                style="font-size: 1rem"
              ></fa-icon>
              Áudios</span
            >
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            [routerLink]="'programacao'"
            class="nav-link no-caret"
            data-toggle="dropdown"
            role="button"
          >
            <span class="nav-link-inner--text"
              ><fa-icon
                [icon]="['fas', 'clipboard-list']"
                style="font-size: 1rem"
              ></fa-icon>
              Programação</span
            >
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            [routerLink]="'institucional'"
            class="nav-link no-caret"
            data-toggle="dropdown"
            role="button"
          >
            <span class="nav-link-inner--text"
              ><fa-icon
                [icon]="['fas', 'landmark']"
                style="font-size: 1rem"
              ></fa-icon>
              Institucional</span
            >
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            [routerLink]="'contato'"
            class="nav-link no-caret"
            data-toggle="dropdown"
            role="button"
          >
            <span class="nav-link-inner--text">
              <fa-icon
                [icon]="['fas', 'address-book']"
                style="font-size: 1rem"
              ></fa-icon>
              Contato
            </span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <mat-form-field class="small-form-field search-label">
            <input
              placeholder="Pesquisar..."
              #element
              matInput
              type="text"
              (keydown.enter)="searchNewsByKeywords(element.value)"
            />
            <button
              (click)="searchNewsByKeywords(element.value)"
              matSuffix
              mat-icon-button
              aria-label="Limpar"
            >
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </li>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/radioagudofm"
            target="_blank"
            data-toggle="tooltip"
            title="Curta nossa página no Facebook!"
          >
            <fa-icon
              [icon]="['fab', 'facebook-square']"
              class="sizeIcon"
            ></fa-icon>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/radio_agudo/"
            target="_blank"
            data-toggle="tooltip"
            title="Siga-nos no Instagram!"
          >
            <fa-icon [icon]="['fab', 'instagram']" class="sizeIcon"></fa-icon>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://api.whatsapp.com/send?phone=5555999971613"
            target="_blank"
            data-toggle="tooltip"
            title="Entre em contato conosco no WhatsApp!"
          >
            <fa-icon
              [icon]="['fab', 'whatsapp-square']"
              class="sizeIcon"
            ></fa-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div
  class="d-none d-md-block"
  style="
    padding-top: 100px;
    padding-bottom: 20px;
    text-align: center;
    background: linear-gradient(87deg, #3f5c9a 0, #3c5a99 100%) !important;
  "
>
  <div class="container p-1">
    <div class="row d-flex justify-content-between m-0">
      <div class="col-lg-4 col-md-3 col-12 p-0" style="text-align: start">
        <a [routerLink]="'home'"
          ><img
            alt="Rádio Agudo"
            src="assets/img/brand/brandColored.webp"
            alt="Rádio Agudo"
            style="max-width: 100%; height: auto; max-height: 120px"
        /></a>
      </div>
      <div class="col-lg-8 col-md-8 col-12 d-md-block p-0">
        <div class="row" style="color: whitesmoke; margin: 0">
          <span style="color: whitesmoke; margin: 0; font-size: 0.8rem"
            >Publicidade</span
          >
        </div>
        <div class="row" style="color: whitesmoke; margin: 0">
          <a [href]="getAd('TOPO').link" target="_blank">
            <img
              alt="Apoio"
              loading="lazy"
              *ngIf="getAd('TOPO').photo"
              style="width: 100%; max-height: 100px"
              [src]="'https://radioagudo.com.br' + getAd('TOPO').photo"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="d-md-none"
  [ngStyle]="{ 'background-color': isHome() ? '#ced4da2b' : 'white' }"
  style="padding-top: 80px; padding-bottom: 20px; text-align: center"
>
  <div class="row" style="color: whitesmoke; margin: 0">
    <span
      [ngStyle]="{ color: isHome() ? 'gray' : 'gray' }"
      style="padding-left: 15px; margin: 0; font-size: 0.8rem"
      >Publicidade</span
    >
  </div>
  <div class="row" style="color: whitesmoke; margin: 0">
    <div class="col-sm">
      <a [href]="getAd('TOPO').link" target="_blank">
        <img
          alt="Apoio"
          loading="eager"
          fetchpriority="high"
          *ngIf="getAd('TOPO').photo"
          style="
            width: 99%;
            max-width: 800px;
            text-align: center;
            max-height: 100px;
          "
          [src]="'https://radioagudo.com.br' + getAd('TOPO').photo"
        />
      </a>
    </div>
  </div>
</div>
