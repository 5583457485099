import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Event} from '../../models/Event';

@Injectable()
export class CalendarProvider {

    constructor(public http: HttpClient) {
    }

    public apiBase = 'https://radioagudo.com.br/api/';

    getEvents() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase  + 'agenda').subscribe((events: Event[]) => {
                    resolve(events);
                },
                (error) => {
                    reject(error);
                });
        });
    }

}
