import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeAgo',
})
export class CalculeTime implements PipeTransform {
    transform(value: any): any {
        return 'Há ' + moment(value, 'DD/MM/YYYY HH:mm:ss').locale('pt-br').fromNow(true);
    }
}
