import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Audios} from '../../models/Audios';
import {AudioCategory} from '../../models/AudioCategory';

@Injectable()
export class AudiosProvider {

    constructor(public http: HttpClient) {
    }

    public apiBase = 'https://radioagudo.com.br/api/';

    getLastAudios() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase  + 'audios').subscribe((audio: Audios[]) => {
                    resolve(audio);
                },
                (error) => {
                    reject(error);
                });
        });
    }

    getAudiosBeforeId(id: any) {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase + 'audios/before/' + id).subscribe((audio: Audios[]) => {
                    resolve(audio);
                },
                (error) => {
                    reject(error);
                });
        });
    }

    getAudiosById(id: any) {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase + 'audios/id/' + id).subscribe((audio: Audios) => {
                    resolve(audio);
                },
                (error) => {
                    reject(error);
                });
        });
    }


    getAudiosBySpotlight() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase + 'audios/destaque/1').subscribe((audio: Audios[]) => {
                    resolve(audio);
                },
                (error) => {
                    reject(error);
                });
        });
    }

    getAudiosByCategory(categoryId: any) {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase + 'audios/categoria/' + categoryId).subscribe((audio: Audios) => {
                    resolve(audio);
                },
                (error) => {
                    reject(error);
                });
        });
    }

    getAudioCategories() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase + 'audios/categoria').subscribe((audio: AudioCategory[]) => {
                    resolve(audio);
                },
                (error) => {
                    reject(error);
                });
        });
    }
}
