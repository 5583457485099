import { Component, OnInit } from '@angular/core';
import {ScheduleProvider} from '../../providers/schedule/scheduleProvider';
import * as moment from 'moment';
import {MetaAndTitle} from '../shared/metaAndTitle/metaAndTitle';

class Programa {
  programa: string;
  nome: string;
  data: string;
  hora: string;
  fim: string;
}

class Programacao {
  day: string;
  list: Programa[];
}

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  public schedule: Programacao[];
  public currentSchedule: string;

  constructor(private scheduleProvider: ScheduleProvider,
              private metaAndTitle: MetaAndTitle) { }

  ngOnInit(): void {
    this.metaAndTitle.setMetaAndTitle('Programação', '/programacao');
    this.getSchedule();
    this.getCurrentSchedule();
  }

  getCurrentSchedule() {
    this.scheduleProvider.getScheduleNow().then((currentSchedule: any[]) => {
      this.currentSchedule = currentSchedule[0].programa;
    });
  }

  getSchedule() {
    this.scheduleProvider.getAllSchedule().then((result: Programacao[]) => {
     result.forEach((day) => {
       day.list.sort((a, b) => {
         return +a.hora.split(':')[0] - +b.hora.split(':')[0];
       });
     });
      this.schedule = result;
    });
  }

  getDay() {
    return moment().locale('pt-br').format('dddd');
  }

  clockFormat(clock) {
    const splitedClock = clock.split(':');
    return splitedClock[0] + 'h' + splitedClock[1] + 'min';
  }
}
