<section class="sections" style="margin-bottom: 5%">
    <div class="container">
        <h1>Falecimentos</h1>
        <mat-divider class="divider"></mat-divider>
        <div class="card-columns">
            <a *ngFor="let falecimento of obituary" [routerLink]="['/falecimentos/' + removeAcento(falecimento.titulo) + '/' + falecimento.id]">
            <div class="card">
                <div class="card-body shadow">
                    <h3 class="card-title">{{falecimento.titulo}}</h3>
                </div>
            </div>
            </a>
<!--            <div class="card p-3">-->
<!--                <blockquote class="blockquote mb-0 card-body">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>-->
<!--                    <footer class="blockquote-footer">-->
<!--                        <small class="text-muted">-->
<!--                            Someone famous in <cite title="Source Title">Source Title</cite>-->
<!--                        </small>-->
<!--                    </footer>-->
<!--                </blockquote>-->
<!--            </div>-->
<!--            <div class="card">-->
<!--                <img loading="lazy" class="card-img-top" src="..." alt="Card image cap">-->
<!--                <div class="card-body">-->
<!--                    <h5 class="card-title">Card title</h5>-->
<!--                    <p class="card-text">This card has supporting text below as a natural lead-in to additional-->
<!--                        content.</p>-->
<!--                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
<!--                </div>-->
<!--            </div>-->
            <!--    <div class="card bg-primary text-white text-center p-3">-->
            <!--        <blockquote class="blockquote mb-0">-->
            <!--            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.</p>-->
            <!--            <footer class="blockquote-footer">-->
            <!--                <small>-->
            <!--                    Someone famous in <cite title="Source Title">Source Title</cite>-->
            <!--                </small>-->
            <!--            </footer>-->
            <!--        </blockquote>-->
            <!--    </div>-->
            <!--    <div class="card text-center">-->
            <!--        <div class="card-body">-->
            <!--            <h5 class="card-title">Card title</h5>-->
            <!--            <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>-->
            <!--            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
            <!--        </div>-->
            <!--    </div>-->
            <!--    <div class="card">-->
            <!--        <img loading="lazy" class="card-img" src="..." alt="Card image">-->
            <!--    </div>-->
            <!--    <div class="card p-3 text-right">-->
            <!--        <blockquote class="blockquote mb-0">-->
            <!--            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>-->
            <!--            <footer class="blockquote-footer">-->
            <!--                <small class="text-muted">-->
            <!--                    Someone famous in <cite title="Source Title">Source Title</cite>-->
            <!--                </small>-->
            <!--            </footer>-->
            <!--        </blockquote>-->
            <!--    </div>-->
            <!--    <div class="card">-->
            <!--        <div class="card-body">-->
            <!--            <h5 class="card-title">Card title</h5>-->
            <!--            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>-->
            <!--            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
            <!--        </div>-->
            <!--    </div>-->
        </div>
    </div>
</section>
