import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  HostListener,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { Location } from "@angular/common";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { interval } from "rxjs";
import { filter, map } from "rxjs/operators";

let didScroll;
let lastScrollTop = 0;
let delta = 5;
let navbarHeight = 0;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private _router: Subscription;

  private cookieDismiss = "Fechar";
  private cookieMessage =
    "Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços." +
    " Ao utilizar nossos serviços, você concorda com tal monitoramento." +
    ' <a target="_blank" href="https://radioagudo.com.br/agudo/declaracao_de_cookies.html">Leia mais </a>';
  private cookieLinkText: "white";

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private swUpdate: SwUpdate,
    private router: Router,
    private element: ElementRef,
    public location: Location
  ) {
    iconRegistry.addSvgIcon(
      "whatsapp",
      sanitizer.bypassSecurityTrustResourceUrl(
        "./assets/img/icons/common/whatsapp-square-brands.svg"
      )
    );

    interval(6 * 60 * 60).subscribe(() =>
      this.swUpdate.checkForUpdate().then((isNewVersion) => {
        if (this.swUpdate.isEnabled && isNewVersion) {
          if (confirm("Nova versão disponível. Deseja atualizar?")) {
            this.swUpdate.activateUpdate().then((value) => {
              console.log(
                value
                  ? "Aplicação atualizada com sucesso"
                  : "Não foi possível atualizar a aplicação ou a versão mais recente já está instalada"
              );
              if (value) {
                window.location.reload();
              }
            });
          }
        }
      })
    );
  }

  @HostListener("window:scroll", ["$event"])
  hasScrolled() {
    let st = window.pageYOffset;
    if (Math.abs(lastScrollTop - st) <= delta) {
      return;
    }

    let navbar = document.getElementsByTagName("nav")[0];
    if (st > lastScrollTop && st > navbarHeight) {
      if (navbar.classList.contains("headroom--pinned")) {
        navbar.classList.remove("headroom--pinned");
        navbar.classList.add("headroom--unpinned");
      }
    } else {
      if (st + window.innerHeight < document.body.scrollHeight) {
        if (navbar.classList.contains("headroom--unpinned")) {
          navbar.classList.remove("headroom--unpinned");
          navbar.classList.add("headroom--pinned");
        }
      }
    }

    lastScrollTop = st;
  }

  ngOnInit() {
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969",
        },
        button: {
          background: "#ffe000",
          text: "#164969",
        },
      },
      theme: "classic",
      content: {
        message: this.cookieMessage,
        dismiss: this.cookieDismiss,
        link: this.cookieLinkText,
        href: "https://radioagudo.com.br",
      },
    });

    let navbar: HTMLElement =
      this.element.nativeElement.children[0].children[0];
    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (window.outerWidth > 991) {
          window.document.children[0].scrollTop = 0;
        } else {
          window.document.activeElement.scrollTop = 0;
        }
        this.renderer.listen("window", "scroll", (event) => {
          const number = window.scrollY;
        });
      });
    this.hasScrolled();
  }
}
