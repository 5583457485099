import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Audios} from '../../models/Audios';
import {AudioCategory} from '../../models/AudioCategory';
import {Ads} from '../../models/Ads';

@Injectable()
export class AdsProvider {

    constructor(public http: HttpClient) {
    }

    public apiBase = 'https://radioagudo.com.br/api/';

    getAds() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase  + 'apoio').subscribe((ads: Ads[]) => {
                    resolve(ads);
                },
                (error) => {
                    reject(error);
                });
        });
    }

}
