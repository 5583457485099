import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class ScheduleProvider {


    constructor(public http: HttpClient) {
    }

    public apiBase2 = 'https://radioagudo.com.br/api/programacao/';

    getScheduleNow() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase2
                + moment().locale('pt-br').format('dddd').toLowerCase() + '\'').subscribe((schedule: any) => {
                    resolve(schedule);
                },
                (error) => {
                    reject(error);
                });
        });
    }

    getAllSchedule() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase2).subscribe((schedule: any) => {
                    resolve(schedule);
                },
                (error) => {
                    reject(error);
                });
        });
    }

}
