import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {News} from '../../models/News';
import {Obituary} from '../../models/Obituary';

@Injectable()
export class ObituaryProvider {

    constructor(public http: HttpClient) {
    }

    public apiBase = 'https://radioagudo.com.br/api/falecimentos';

    getLastDeaths() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase).subscribe((news: Obituary[]) => {
                    resolve(news);
                },
                (error) => {
                    reject(error);
                });
        });
    }

    getObituaryById(id) {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase + '/' + id).subscribe((news: Obituary) => {
                    resolve(news);
                },
                (error) => {
                    reject(error);
                });
        });
    }

}
