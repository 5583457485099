import { Component, OnInit } from '@angular/core';
import {AudiosProvider} from '../../providers/audios/audiosProvider';
import {Audios} from '../../models/Audios';
import {GlobalConstants} from '../shared/global-constants';
import {AudioCategory} from '../../models/AudioCategory';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RemoveCaracteres} from '../../pipes/removeCaracteres';
import { Location } from '@angular/common';
import {MetaAndTitle} from '../shared/metaAndTitle/metaAndTitle';

@Component({
  selector: 'app-audios',
  templateUrl: './audios.component.html',
  styleUrls: ['./audios.component.scss']
})
export class AudiosComponent implements OnInit {

  public audios: Audios[];
  private indexAux: any;
  public audioCategories: AudioCategory[];
  audiosDestaque: Audios[];
  private params: Params;

  constructor(private activatedRoute: ActivatedRoute,
              private location: Location,
              private metaAndTitle: MetaAndTitle,
              private router: Router,
              private audiosProvider: AudiosProvider) {
    this.metaAndTitle.setMetaAndTitle('Áudios', '/audios');
    this.activatedRoute.params.subscribe(params => {
      this.params = (params);
      if (this.audioCategories) {
        this.getAudios();
      }
    });
  }

  removeAcento(value) {
    const pipeCaracteres = new RemoveCaracteres();
    return pipeCaracteres.transform(value);
  }

  ngOnInit(): void {
    this.getAudiosSpotlight();
    this.getCategories();
    if (this.params['id']) {
      setTimeout(() => {
        this.getAudioById(this.params['id']);
      });
    }
  }

  getCategories() {
    this.audiosProvider.getAudioCategories().then((audioCategories: AudioCategory[]) => {
      this.audioCategories = audioCategories;
      this.getAudios();
    });
  }

  getAudiosByCategory(categoryId: number) {
    this.audiosProvider.getAudiosByCategory(categoryId).then((audioCategories: Audios[]) => {
      this.audios = audioCategories;
    });
  }

  getImageSize(image): boolean {
    const img = new Image();
    img.src = image.includes('http') ? image : 'https://radioagudo.com.br' + image;
    return img.width < img.height;
  }

  getAudiosSpotlight() {
    this.audiosProvider.getAudiosBySpotlight().then((audiosSpotlight: Audios[]) => {
      this.audiosDestaque = audiosSpotlight;
    });
  }

  getAudioById(id: number) {
    this.audiosProvider.getAudiosById(id).then((audio: any) => {
      this.metaAndTitle.setMetaAndTitle(audio.titulo, this.router.url, '', audio.foto, 'Áudios', audio.categoria.nome);
      setTimeout(() => {
        this.changeFont(audio);
      });
    });
  }

  getAudios() {
    if (this.params['categoria'] !== undefined) {
      this.getAudiosByCategory(this.audioCategories.find(obj => this.removeAcento(obj.nome) === (this.params)['categoria']).id);
    } else {
      this.audiosProvider.getLastAudios().then((audios: Audios[]) => {
        this.audios = audios;
      });
    }
  }

  changeFont(audio: any) {
    console.log(audio);
    this.location.replaceState('/audios/' + this.removeAcento(audio.categoria.nome) + '/' +
        this.removeAcento(audio.titulo) + '/' + this.removeAcento(audio.id));
    GlobalConstants.playerActive = false;
    GlobalConstants.audioInfo = {
      name: audio.titulo,
      photo: audio.foto,
      content: audio.conteudo.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ''),
      type: audio.categoria
    };
    GlobalConstants.indice = 0;
    GlobalConstants.sources = [
      {
        src: 'https://radioagudo.com.br' + audio.file,
        type: 'audio/mp3',
        titulo: audio.titulo,
        foto: audio.foto
      }
    ];
    setTimeout(() => {
      GlobalConstants.playerActive = true;
    });
  }

  removeHtmlTags(value) {
  return value.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '');
  }

  nextBatch(index) {
    let lastIndex;
    if (this.audios !== undefined) {
      lastIndex = this.audios.length - 1;
    }
    if (this.indexAux !== lastIndex) {
      this.indexAux = lastIndex;

        this.audiosProvider.getAudiosBeforeId(this.audios[lastIndex].id).then((result: Audios[]) => {
          this.audios = this.audios.concat(result);
        });
    }
  }

}
