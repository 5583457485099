import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TemperatureProvider {
    // https://radioagudo.com.br/android_connect/checar_temperatura.php

    constructor(public http: HttpClient) {
    }

    public apiBase = 'https://radioagudo.com.br/api/tempo';

    getTemperature() {
        return new Promise((resolve, reject) => {
            this.http.get(this.apiBase).subscribe((temperature: any) => {
                    resolve(temperature);
                },
                (error) => {
                    reject(error);
                });
        });
    }

}
