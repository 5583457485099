<section class="sections" style="margin-bottom: 0;  padding: 1% 5% 5% 5%;">
    <div class="container">


        <div class="row">

            <div class="col-sm"
                 infiniteScroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 (scrolled)="nextBatch($event)">
                <h2 id="audiosList">Áudios</h2>
                <div (click)="changeFont(audio)" *ngFor="let audio of audios" class="card col-sm shadow"
                     style="min-width: 30%; margin: 10px 10px 10px 0px; cursor: pointer; border: 1px solid #ccc;">
                    <div class="row">
                        <div *ngIf="audio.categoria !== null && audio.categoria.img !== null && audio.categoria.tipo === 'PEOPLE' && audio.categoria.img !== ''">
                            <img loading="lazy" *ngIf="audio.categoria.tipo === 'PEOPLE'" src="https://radioagudo.com.br/{{audio.categoria.img}}"
                                 style="max-height: 200px; max-width: 100%;" [alt]="audio.titulo">
                        </div>
                        <div *ngIf="!audio.foto.includes('semfoto') && audio.foto != ''" class="col-4"
                             style="padding: 0">

                            <img loading="lazy" *ngIf="audio.categoria === null || audio.categoria.tipo !== 'PEOPLE'" src="https://radioagudo.com.br/{{audio.foto}}"
                              style="max-height: 200px; max-width: 100%;" [alt]="audio.titulo">

<!--                            <img loading="lazy" [src]="'https://radioagudo.com.br' + audio.foto"-->
<!--                                 style="max-height: 200px; max-width: 100%;" [alt]="audio.titulo">-->
                        </div>
                        <div class="col">
                            <p style="margin-top: 7px; margin-bottom: 3px;">
                                <span [routerLink]="'/audios/' + this.removeAcento(audio.categoria.nome)" [matTooltip]="'Clique para ver mais áudios de ' + audio.categoria.nome" class="bg-blue" style="color: white; border-radius: 5px; padding: 2px;"
                                      *ngIf="audio.categoria !== null">
                                 {{audio.categoria.nome}}
                                </span>
                            </p>
                            <h3 style="padding: 10px 0 0 0">{{audio.titulo}}</h3>
                            <p>{{removeHtmlTags(audio.conteudo)}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <mat-list>
                    <div mat-subheader><h2>Categorias</h2></div>
                    <!--                    <mat-form-field class="example-form-field">-->
                    <!--                        <mat-label>Pesquisar categoria</mat-label>-->
                    <!--                        <input matInput type="text">-->
                    <!--                        &lt;!&ndash;                     <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                         <mat-icon>close</mat-icon>&ndash;&gt;-->
                    <!--                        &lt;!&ndash;                     </button>&ndash;&gt;-->
                    <!--                    </mat-form-field>-->
                    <mat-list-item>
                        <button routerLink="/audios" fragment="audiosList" mat-raised-button class="shadow-none">
                            <fa-icon [icon]="['fas', 'file-audio']"
                                     style="font-size: 1.5rem; margin-right: 5px"></fa-icon>
                            Todas categorias
                        </button>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                    <ng-container *ngFor="let category of audioCategories">
                        <ng-container *ngIf="category.tipo === 'GENERAL'">
                            <mat-list-item>
                                <button [routerLink]="'/audios/' + removeAcento(category.nome)" fragment="audiosList"  mat-raised-button
                                        class="shadow-none">
                                    <fa-icon [icon]="['fas', 'file-audio']"
                                             style="font-size: 1.5rem; margin-right: 5px"></fa-icon>
                                    {{category.nome}}
                                </button>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>
                    </ng-container>
                </mat-list>

            </div>
        </div>
        <!--     <div   infiniteScroll-->
        <!--             [infiniteScrollDistance]="2"-->
        <!--             [infiniteScrollThrottle]="50"-->
        <!--             (scrolled)="nextBatch($event)" class="row" style="margin: 0; margin-top: 10px;">-->

        <!--        <div (click)="changeFont(audio)" *ngFor="let audio of audios" class="card col-sm shadow"-->
        <!--             style="min-width: 30%; margin: 10px; cursor: pointer">-->
        <!--            <img loading="lazy" [src]="'https://radioagudo.com.br' + audio.foto" class="rounded-circle" style="max-height: 200px;">-->
        <!--            <h3>{{audio.titulo}}</h3>-->
        <!--            <p>{{removeHtmlTags(audio.conteudo)}}</p>-->
        <!--        </div>-->
        <!--    </div>-->
    </div>
</section>
