import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import {AdsProvider} from '../../../providers/ads/adsProvider';
import {Ads} from '../../../models/Ads';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    constructor(public location: Location,
                private adsProvider: AdsProvider,
                private router: Router) {
    }

    ngOnInit() {
      this.getAndStoreAds();
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url !== this.lastPoppedUrl) {
               this.yScrollStack.push(window.scrollY);
           }
       } else if (event instanceof NavigationEnd) {
           if (event.url === this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else {
               window.scrollTo(0, 0);
           }
       }
     });
     this.location.subscribe((ev: PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
    }

    getAndStoreAds() {
        this.adsProvider.getAds().then((ads: Ads[]) => {
            sessionStorage.setItem('ads', JSON.stringify(ads));
        });
    }

    getAd(location) {
        const ads: Ads[] = JSON.parse(sessionStorage.getItem('ads'));
        let add = ads == null ? null : ads.find(ad => ad.place === location);
        if (add === null) {
            add = new Ads();
            add.link = undefined;
        }
        return add;
    }

    isHome() {
        const titlee = this.location.prepareExternalUrl(this.location.path());
        return titlee === '/home';
    }
    isDocumentation() {
        const titlee = this.location.prepareExternalUrl(this.location.path());
        return titlee === '#/documentation';
    }

    searchNewsByKeywords(value: string) {
        const keywords = value.split(' ');
        this.router.navigate(['/busca/noticias'],
            {queryParams: {keyword: keywords}});
    }
}
