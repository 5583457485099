import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ObituaryComponent } from "./obituary/obituary.component";
import { AllObituaryComponent } from "./obituary/all-obituary/all-obituary.component";
import { AudiosComponent } from "./audios/audios.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { AboutComponent } from "./about/about.component";
import { SearchComponent } from "./news/search/search.component";
import { YoutubeComponent } from "./youtube/youtube.component";

export const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  { path: "audios", component: AudiosComponent },
  { path: "Audios", component: AudiosComponent },
  { path: "audios/:categoria", component: AudiosComponent },
  { path: "audios/:categoria/:titulo/:id", component: AudiosComponent },
  { path: "Audio/:id", component: AudiosComponent },
  {
    path: "noticias",
    loadChildren: () => import("./news/news.module").then((m) => m.NewsModule),
  },
  { path: "busca/:keywords", component: SearchComponent },
  { path: "falecimentos/:titulo/:id", component: ObituaryComponent },
  { path: "Falecimento/:id", component: ObituaryComponent },
  { path: "youtube/:id", component: YoutubeComponent },
  { path: "Falecimento", component: AllObituaryComponent },
  { path: "falecimentos", component: AllObituaryComponent },
  { path: "programacao", component: ScheduleComponent },
  {
    path: "contato",
    loadChildren: () =>
      import("./contact/contact.module").then((m) => m.ContactModule),
  },
  { path: "institucional", component: AboutComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "**", redirectTo: "/home" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true
}),
  ],
  exports: [],
})
export class AppRoutingModule {}
