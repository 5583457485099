import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {ScheduleProvider} from '../../../providers/schedule/scheduleProvider';
import {TemperatureProvider} from '../../../providers/temperature/temperatureProvider';
import * as moment from 'moment';
import { GlobalConstants } from '../global-constants';
import Global = WebAssembly.Global;
import set = Reflect.set;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    @ViewChild('vgPlayer', {static: false}) vgPlayer;
    @ViewChild('vgControls', {static: false}) vgControls;
    @ViewChild('media', {static: false}) mediaPlayer;
    test: Date = new Date();
    programacaoAgora: any;
    temperatura: any;
    sources = [
        {
            src: 'https://streaming.radioagudo.com.br/radio/8000/vivo?1571154613',
            type: 'audio/aac'
        }];
    pausou: boolean;
    audioPause = false;
    indice: number = 0;
    api: any;
    apiAudios: any;
    constructor(private router: Router,
                private temperatureProvider: TemperatureProvider,
                private scheduleProvider: ScheduleProvider) {}

    ngOnInit() {
        this.getScheduleNow();
        this.getTemperatureNow();
        setInterval(() => {
            this.getScheduleNow();
            this.getTemperatureNow();
        }, 900000);    
        GlobalConstants.playerActive = false;
    }

    getTemperatureNow() {
        this.temperatureProvider.getTemperature().then(result => {
            this.temperatura = result;
        });
    }

    getScheduleNow() {
        this.scheduleProvider.getScheduleNow().then(result => {
           this.programacaoAgora = result;
        }).catch(result => {
            console.error(result);
        });
    }

    playPauseAudio() {
        this.audioPause = !this.audioPause;
        if (!this.audioPause) {
            this.apiAudios.play();
        } else {
            this.apiAudios.pause();
        }
    }

    audiosIsOn(): boolean {
        return GlobalConstants.playerActive;
    }

    getWeatherIcon(condition) {
        switch (condition) {
            case 'Tempestade forte':
                return 'cloud-showers-heavy';
            case 'Tempestade tropical':
                return 'poo-storm';
            case 'Furacão':
                return 'wind';
            case 'Tempestades severas':
                return 'poo-storm';
            case 'Tempestades':
                return 'poo-storm';
            case 'Misto de neve e chuva':
                return 'snowflake';
            case 'Misto chuva e gelo':
                return 'snowflake';
            case 'Misto neve e gelo':
                return 'snowflake';
            case 'Geada fina':
                return 'smog';
            case 'Chuviscos':
                return 'cloud-rain';
            case 'Congelamento chuva':
                return 'cloud-rain';
            case 'Alguns chuviscos':
                return 'cloud-rain';
            case 'Neve baixa':
                return 'snowflake';
            case 'Tempestade com neve':
                return 'snowflake';
            case 'Ventania com neve':
                return 'snowflake';
            case 'Neve':
                return 'snowflake';
            case 'Granizo':
                return 'cloud-rain';
            case 'Gelo':
                return 'snowflake';
            case 'Poeira':
                return 'wind';
            case 'Neblina':
                return 'smog';
            case 'Tempestade de areia':
                return 'wind';
            case 'Fumacento':
                return 'wind';
            case 'Vento acentuado':
                return 'wind';
            case 'Ventania':
                return 'wind';
            case 'Tempo frio':
                return 'snowflake';
            case 'Tempo nublado':
                return 'cloud';
            case 'Parcialmente nublado':
                return 'cloud-sun';
            case 'Tempo limpo':
                return 'sun';
            case 'Ensolarado':
               return 'sun';
            case 'Estrelado':
                return 'star';
            case 'Ensolarado com muitas nuvens':
                return 'cloud-sun';
            case 'Misto chuva e granizo':
                return 'cloud-showers-heavy';
            case 'Ar quente':
                return 'wind';
            case 'Trovoadas dispersas':
                return 'poo-storm';
            case 'Chuvas esparsas':
                return 'cloud-rain';
            case 'Pesados neve':
                return 'snowflake';
            case 'Chuviscos com neve':
                return 'cloud-rain';
            case 'Neve pesada':
                return 'snowflake';
            case 'Sol com poucas nuvens':
                return 'cloud-rain';
            case 'Chuva':
                return 'cloud-rain';
            case 'Queda de neve':
                return 'snowflake';
            case 'Tempestades isoladas':
                return 'poo-storm';
            case 'Serviço não disponível':
                return 'sun';
        }
    }

    getTimeFormated(time) {
        return moment(time, 'HH:mm:ss').format('HH:mm');
    }

    getPath() {
      return this.router.url;
    }

    onPlayerReady(api: any) {
        this.api = api;
    }

    onPlayerReadyAudios(api: any) {
        this.audioPause = false;
        GlobalConstants.api = api;
        this.apiAudios = GlobalConstants.api;
    }

    getSourcesList() {
        return GlobalConstants.sources;
    }

    getSources() {
        this.indice = GlobalConstants.indice;
        GlobalConstants.audioInfo.titulo = GlobalConstants.sources[this.indice].titulo;
        GlobalConstants.audioInfo.foto = GlobalConstants.sources[this.indice].foto;
        return GlobalConstants.sources[this.indice];
    }

    previousAudio() {
        if (GlobalConstants.indice > 0) {
            GlobalConstants.indice--;
        }
    }

    nextAudio() {        
        if (GlobalConstants.indice < GlobalConstants.sources.length - 1) {
            GlobalConstants.indice++;
        }
    }

    getInfoAudio() {
        return GlobalConstants.audioInfo;
    }


    onClickUpdateSource(pausou: boolean) {
        this.pausou = pausou;
        // this.api.pause();
        this.sources = [
            {
                src: '',
                type: 'audio/aac'
            }
        ];
        this.sources =  [
            {
                src: 'https://streaming.radioagudo.com.br/radio/8000/vivo?1571154613',
                type: 'audio/aac'
            }];
    }

    closeAudio() {
        GlobalConstants.playerActive = false;
    }

    play(api) {
        this.onPlayerReady(api);
        this.sources = [
            {
                src: '',
                type: 'audio/aac'
            }
        ];
        setTimeout(() => {
            this.sources =  [
                {
                    src: 'https://streaming.radioagudo.com.br/radio/8000/vivo?1571154613',
                    type: 'audio/aac'
                }];
        });
        setTimeout(() => {
            this.api.play();
        });
    }
}
