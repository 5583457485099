<footer
  class="fixed-bottom bg-footer text-white shadow text-center"
  style="opacity: 92.5%"
  [ngClass]="{
    'has-cards':
      getPath() !== '/user-profile' &&
      getPath() !== '/register' &&
      getPath() !== '/login'
  }"
>
  <div class="container">
    <div class="row" *ngIf="audiosIsOn()" style="border-bottom: 1px solid gray">
      <div class="col-sm-7" style="width: 100%">
        <div class="row" style="padding: 8px; margin: 0">
            <div
            class="d-flex justify-content-between align-items-center"
            [ngClass]="{
              'justify-content-center': getSourcesList().length <= 1,
              'justify-content-between': getSourcesList().length > 1
            }"
            style="width: 100%"
            >
            <button
              *ngIf="getSourcesList().length > 1"
              [disabled]="indice === 0"
              mat-button
              (click)="previousAudio(); media2.load()"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Áudio anterior"
              class="m-1 p-0"
            >
              <fa-icon
              [icon]="['fas', 'backward']"
              [ngStyle]="{
                'font-size': '1.5rem',
                color: indice === 0 ? 'gray' : 'whitesmoke',
                'padding-left': '5px',
                'text-align': 'left'
              }"
              >
              </fa-icon>
            </button>
            <audio
              #media2
              id="myAudio2"
              preload="auto"
              controls="true"
              style="width: 100%"
              controlsList="nodownload"
              autoplay
              (ended)="nextAudio(); media2.load()"
            >
              <source [src]="getSources().src" [type]="getSources().type" />
            </audio>
            <button
              *ngIf="getSourcesList().length > 1"
              [disabled]="indice === getSourcesList().length - 1"
              mat-button
              (click)="nextAudio(); media2.load()"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Próximo áudio"
              alt="Próxima"
              class="m-1 p-0"
            >
              <fa-icon
              [icon]="['fas', 'forward']"
              [ngStyle]="{
                'font-size': '1.5rem',
                color: indice === getSourcesList().length - 1 ? 'gray' : 'whitesmoke',
                'padding-left': '5px',
                'text-align': 'left'
              }"
              >
              </fa-icon>
            </button>
            </div>
        </div>
        <span
          class="text-white d-block d-sm-none"
          style="
            margin: 0;
            font-size: 1.1rem;
            text-shadow: -1px -1px 0 #555454, 1px -1px 0 #555454,
              -1px 1px 0 #555454, 1px 1px 0 #555454;
          "
        >
          {{ getSources().titulo }}</span
        >
        <span class="d-block d-sm-none">
          <ng-marquee
            *ngIf="
              getInfoAudio().content !== undefined &&
              getInfoAudio().content !== ''
            "
          >
            <mark
              style="
                color: #1414fc;
                background-color: transparent;
                text-shadow: -1px -1px 0 #8e8e8e, 1px -1px 0 #8b8888,
                  -1px 1px 0 #929292, 1px 1px 0 #837f7f;
              "
              >{{ getInfoAudio().content }}</mark
            >
          </ng-marquee>
        </span>
        <div
          class="row"
          style="
            width: 100%;
            background: grey;
            border-radius: 15px 15px 0 0;
            margin: 0;
          "
        >
          <div class="col-sm d-none d-md-block" style="padding: 8px">
            <span
              class="text-white"
              style="
                margin: 0;
                font-size: 1.1rem;
                text-shadow: -1px -1px 0 #555454, 1px -1px 0 #555454,
                  -1px 1px 0 #555454, 1px 1px 0 #555454;
              "
            >
              {{ getSources().titulo }}</span
            >
            <ng-marquee
              *ngIf="
                getInfoAudio().content !== undefined &&
                getInfoAudio().content !== ''
              "
            >
              <mark
                style="
                  color: #1414fc;
                  background-color: transparent;
                  text-shadow: -1px -1px 0 #8e8e8e, 1px -1px 0 #8b8888,
                    -1px 1px 0 #929292, 1px 1px 0 #837f7f;
                "
                >{{ getInfoAudio().content }}</mark
              >
            </ng-marquee>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="col-sm d-none d-md-block" style="padding: 8px">
          <img
            loading="lazy"
            [src]="'https://radioagudo.com.br' + getSources().foto"
            style="max-height: 100px; border-radius: 5px 5px 5px 5px"
          />
            <button
            mat-button
            class="ms-1"
            (click)="closeAudio()"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Fechar entrevista"
            >
            <fa-icon
              [icon]="['fas', 'times-circle']"
              style="font-size: 1.5rem; color:whitesmoke; padding-left: 5px; text-align: left"
            >
            </fa-icon>
            </button>
        </div>
      </div>
    </div>
    <div *ngIf="!audiosIsOn()" class="row">
      <div
        *ngIf="temperatura"
        class="col-4 d-none d-md-block"
        style="padding: 8px"
      >
        <h1 class="text-white" style="margin: 0">
          <fa-icon
            [icon]="['fas', 'thermometer-quarter']"
            style="font-size: 1.5rem; padding-right: 5px"
          ></fa-icon>
          {{ temperatura.temperatura }}°C
        </h1>
        <h3 class="text-white" style="margin: 0">
          <fa-icon
            [icon]="['fas', getWeatherIcon(temperatura.descricao)]"
            style="font-size: 1rem; padding-right: 5px"
          ></fa-icon>
          {{ temperatura.descricao }}
        </h3>
      </div>
      <div
        *ngIf="programacaoAgora"
        class="col-sm d-none d-md-block"
        style="padding: 8px"
      >
        <ng-container
          *ngIf="
            programacaoAgora[0] &&
            getTimeFormated(programacaoAgora[0].hora) !== 'Invalid date'
          "
        >
          <h3 class="text-white" style="margin: 0">
            <fa-icon
              [icon]="['fas', 'music']"
              style="font-size: 1rem; padding-right: 5px"
            ></fa-icon>
            {{ programacaoAgora[0].programa }} - {{ programacaoAgora[0].nome }}
          </h3>
          <h3 class="text-white" style="margin: 0">
            {{ getTimeFormated(programacaoAgora[0].hora) }} -
            {{ getTimeFormated(programacaoAgora[0].fim) }}
          </h3>
        </ng-container>
      </div>
      <div class="col-xs-4 col-md-4" style="padding: 8px">
        <audio
          style="width: 100%"
          preload="none"
          controls="true"
          src="https://streaming.radioagudo.com.br/radio/8000/vivo?1571154613"
        ></audio>
      </div>
    </div>
  </div>
</footer>
