import {Component, OnInit} from '@angular/core';
import {ObituaryProvider} from '../../../providers/obituary/obituaryProvider';
import {Obituary} from '../../../models/Obituary';
import {RemoveCaracteres} from '../../../pipes/removeCaracteres';
import {MetaAndTitle} from '../../shared/metaAndTitle/metaAndTitle';

@Component({
  selector: 'app-all-obituary',
  templateUrl: './all-obituary.component.html',
  styleUrls: ['./all-obituary.component.css']
})
export class AllObituaryComponent implements OnInit {

  public obituary: Obituary[];

  constructor(private obituaryProvider: ObituaryProvider,
              private metaAndTitle: MetaAndTitle) {
  }

  ngOnInit(): void {
    this.metaAndTitle.setMetaAndTitle('Falecimentos', '/falecimentos');
    this.getLastObituary();
  }

  getLastObituary() {
    this.obituaryProvider.getLastDeaths().then((obituary: Obituary[]) => {
      this.obituary = obituary;
    });
  }

  removeAcento(value) {
    const pipeCaracteres = new RemoveCaracteres();
    return pipeCaracteres.transform(value);
  }

}
