import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {RemoveCaracteres} from './removeCaracteres';
import {CalculeTime} from './calculeTime';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
    ],
    declarations: [ RemoveCaracteres, CalculeTime ],
    exports: [ RemoveCaracteres, CalculeTime ],
    providers: []
})
export class PipesModule { }
