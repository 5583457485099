<div class="container paddingContainer">
    <h1>Institucional  <mat-divider></mat-divider></h1>
    <p>
        A história da Rádio Agudo tem sua origem no movimento emancipacionista de Agudo. Era projeto acalentado com
        carinho especial pelos jovens que desencadearam a campanha popular para tornar realidade o desejo de progresso e
        autonomia administrativa. Em 1959, Agudo conseguiu sua emancipação. Nos primeiros anos os líderes locais
        concentraram seus esforços para organizar administrativamente o novo município e adiaram o projeto da rádio para
        ocasião mais propícia. Fato que ocorreu em 1966 com a vinda de Leo Pachaly de Porto Alegre, onde atuaria como
        contato junto aos órgãos públicos para promover os interesses da emancipação. Em junho daquele ano, Pachaly
        elaborou um manifesto na forma de “Lista de Adesão”, no qual 21 sócios subscreveram quotas na sociedade – Rádio
        Agudo Ltda – dando caráter definitivo ao empreendimento. Em 1967 foi protocolado requerimento, no então Conselho
        Nacional de Telecomunicações solicitando permissão para instalação da Emissora, fato este consumado no dia 14 de
        julho de 1979. Nesta data, definitivamente entrava no ar a ZYK 336, Rádio Agudo Ltda, na época com 250 whats de
        potência e atuando na frequência de 1560 KHZ. Após um período de 7 anos, passou a trabalhar com 1.000 whats, e
        mais adiante com 2,5 Kilowhats. Em 01 de dezembro de 2017, realizou a migração para FM, onde transmite em 90.1.
    </p>
    <p>Em 1996, a Rádio mudou-se para sede própria, na Av. Concórdia, 1480.
    </p>
    <p>Com uma programação voltada para gosto da população regional, a Emissora está 24 horas no ar, de segunda a
        domingo, sem interrupção. A Rádio Agudo é administrada pelo diretor Sergio Tessele e pelas suas duas filhas,
        Michele Tessele, Gerente Comercial e Simone Tessele, gerente financeiro, conta com 09 colaboradores efetivos na
        área técnica e operacional, alguns com mais de 20 anos de “casa”.
    </p>
    <p>Por iniciativa própria e mesmo em parceria ou apoiando, a Rádio Agudo promoveu com sucesso eventos culturais,
        esportivos, educativos e de congraçamento popular, tais como: Semana do Colono e Motorista, Dia do Vizinho,
        Ressurgimento da Ex-colônia Santo Ângelo, Campeonato Jacuizão, Campanha do Natal Presente; Gincana Aluno Nota
        Dez, Concurso Rainha dos Balneários, Canta Agudo, Volksfest, Festa do Moranguinho, Campanha Florir Agudo, Show
        com os Artistas da Rádio, Destaques de Agudo, etc.
    </p>
    <p>Na sua fundação também foi criado seu primeiro slogan: “Rádio Agudo, uma emissora independente a serviço do povo
        desta região”. Continuamos independentes e agora com RÁDIO AGUDO – ONDAS DO CORAÇÃO.
    </p></div>
