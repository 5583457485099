import { Component, OnInit } from "@angular/core";
import { News } from "../../../models/News";
import { NewsProvider } from "../../../providers/news/newsProvider";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { RemoveCaracteres } from "../../../pipes/removeCaracteres";
import { MetaAndTitle } from "../../shared/metaAndTitle/metaAndTitle";
import { CommonModule } from "@angular/common";
import { PipesModule } from "src/pipes/pipes.module";
import { MatButtonModule } from "@angular/material/button";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    PipesModule,
    MatButtonModule,
    RouterModule,
    FontAwesomeModule,
  ],
})
export class SearchComponent implements OnInit {
  public newsSearch: News[] = [];
  public maxPages = 0;
  public maxResults = 0;
  public page = 1;
  public keywords = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private metaAndTitle: MetaAndTitle,
    private router: Router,
    private newsProvider: NewsProvider
  ) {}

  ngOnInit(): void {
    this.getQueryParamsAndSearch();
  }

  private getQueryParamsAndSearch() {
    this.newsSearch = [];
    this.activeRoute.queryParams.subscribe((value) => {
      this.keywords = value.keyword;
      this.keywords = Array.isArray(value.keyword)
        ? value.keyword
        : (this.keywords = [value.keyword]);
      this.resetIndicators();
      this.getSearchNews(this.keywords, 1);
    });
  }

  private getSearchNews(keywords, page) {
    this.newsSearch = [];
    this.newsProvider
      .getNewsByKeywords(keywords, page)
      .then((value: News[]) => {
        this.updateMetaAndTitle(keywords);
        this.page = page;
        this.newsSearch = value;
        this.maxPages =
          value === null || value.length === 0 ? 0 : value[0].pages;
        this.maxResults = this.maxPages === 0 ? 0 : value[0].results;
      })
      .catch((reason) => {
        console.error(reason);
      });
  }

  private updateMetaAndTitle(keywords: String[]) {
    let title = "Notícias sobre ";
    keywords.forEach((value) => {
      title = title + " " + value;
    });
    this.metaAndTitle.setMetaAndTitle(
      title,
      this.router.url,
      "Notícias",
      " https://radioagudo.com.br/assets/img/brand/brandColored.webp",
      "Busca de notícias",
      "Busca"
    );
  }

  nextPage() {
    const page = this.page + 1;
    this.getSearchNews(this.keywords, page);
  }

  prevPage() {
    const page = this.page - 1;
    this.getSearchNews(this.keywords, page);
  }

  private resetIndicators() {
    this.page = 1;
    this.maxResults = 0;
    this.maxPages = 0;
  }

  /* ############## */
  /* Util functions */
  /* ############## */

  getImageSize(image): boolean {
    const img = new Image();
    img.src = image.includes("http")
      ? image
      : "https://radioagudo.com.br" + image;
    return img.width < img.height;
  }

  removeAcento(value) {
    const pipeCaracteres = new RemoveCaracteres();
    return pipeCaracteres.transform(value);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
