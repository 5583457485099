<section class="sections">

    <div class="container" *ngIf="obituary">

        <h1 class="header1Posts">
            {{obituary.titulo}}
        </h1>

        <h4>{{obituary.datetime}}</h4>
        <mat-divider style="padding: 1rem;"></mat-divider>
        <div class="row">
            <div class="col-sm-8">
                <div class="conteudo-noticia" [innerHTML]="obituary.conteudo"></div>
            </div>

            <div class="col-sm">
                <div class="card bg-white mb-3" style="max-width: 18rem;">
                    <div class="card-header">
                        <h2 style="padding: 0; margin: 0">
                        <a [routerLink]="['/falecimentos/']">    +FALECIMENTOS</a>
                        </h2>
                    </div>
                    <div class="card-body">
                        <ng-container  *ngFor="let obituary of obituaries">
                        <a [routerLink]="['/falecimentos/' + removeAcento(obituary.titulo) + '/' + obituary.id]"><h4 class="card-title">{{obituary.titulo}}
                        </h4>
                            <mat-divider class="divider"></mat-divider>
                        </a>
                        </ng-container>

                        <a [routerLink]="['/falecimentos/']"><h3 class="card-title">
                            <fa-icon [icon]="['fas', 'angle-double-right']"></fa-icon>
                            Ver outros falecimentos
                        </h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
