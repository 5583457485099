import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'removeCaracteres',
})
export class RemoveCaracteres implements PipeTransform {

    transform(text) {
        text = text.toLowerCase().trim();
        text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
        text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
        text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
        text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
        text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
        text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
        text = text.replace(new RegExp('[“]', 'gi'), '');
        text = text.replace(new RegExp('[,]', 'gi'), '');
        text = text.replace(new RegExp('[:]', 'gi'), '');
        text = text.replace(new RegExp('[”]', 'gi'), '');
        text = text.replace(/[\/-]/g, '-');
        text = text.replace(/[\s-]/g, '-');
        text = text.replace(/[\\"]/g, '');
        return text;
    }

}
